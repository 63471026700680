import { FC, Suspense, useMemo } from 'react'
import { Route, Routes } from 'react-router-dom'

import { useAuthentication } from 'hooks'
import routesList from 'libraries/router/routes'
import { HelmetLayout, RouteLoader, RoutesWrapper } from 'components'

import AuthLayout from '../AuthLayout'
import DashboardLayout from '../DashboardLayout'

const PageLayout: FC = () => {
  const { isAuthenticated } = useAuthentication()

  const renderRoutes = useMemo(
    () =>
      routesList.map(({ element: Element, path, title, isPrivate }) => {
        if (isPrivate) {
          return (
            <Route
              key={path}
              path={path}
              element={
                <RoutesWrapper isAuthenticated={isAuthenticated} isPrivate={isPrivate}>
                  <HelmetLayout key={title} title={title}>
                    <DashboardLayout>
                      <Suspense fallback={<RouteLoader />}>
                        <Element />
                      </Suspense>
                    </DashboardLayout>
                  </HelmetLayout>
                </RoutesWrapper>
              }
            />
          )
        }

        return (
          <Route
            key={path}
            path={path}
            element={
              <RoutesWrapper isAuthenticated={isAuthenticated} isPrivate={isPrivate}>
                <HelmetLayout key={title} title={title}>
                  <AuthLayout>
                    <Suspense fallback={<RouteLoader />}>
                      <Element />
                    </Suspense>
                  </AuthLayout>
                </HelmetLayout>
              </RoutesWrapper>
            }
          />
        )
      }),
    [isAuthenticated]
  )

  return <Routes>{renderRoutes}</Routes>
}

export default PageLayout
