import { FC } from 'react'
import { Navigate } from 'react-router-dom'

import { Path } from 'libraries/router/types'

import type { TRoutesWrapper } from './types'

const RoutesWrapper: FC<TRoutesWrapper> = ({ children, isAuthenticated, isPrivate = true }) => {
  if (!isPrivate && isAuthenticated) {
    return <Navigate to={Path.Dashboard} />
  }

  if (isPrivate && !isAuthenticated) {
    return <Navigate to={Path.SignIn} />
  }

  return children
}

export default RoutesWrapper
