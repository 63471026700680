import { FC } from 'react'
import { NavLink } from 'react-router-dom'

// import { Button } from '@amatuni-crypto-academy/amatuni-crypto-academy-education'

// import { useAuthentication } from 'hooks'
import { Path } from 'libraries/router/types'
import { AmatuniPrimaryIcon } from 'assets'
import { ModeSwitch, LangDropdown } from 'components'

import styles from './Header.module.scss'

const Header: FC = () => {
  // const { address, isConnected, isCorrectChain, getAllowance, getAMTBalance, resetBalances, getWalletBalance } =
  //   useWalletConnected()
  // const { addresses } = useAppSelector(web3Selectors.chain)
  // const { chainCheck } = useUtils()
  // const { handleLogout } = useAuthentication()

  // useEffect(() => {
  //   getUserBalances()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isConnected, address])

  // useEffect(() => {
  //   if (isConnected && !isCorrectChain) {
  //     chainCheck()
  //   }

  //   if (isConnected && isCorrectChain) {
  //     showNotification(ToastVersions.success, 'YouWalletHasConnectedSuccessfully')
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isConnected, isCorrectChain])

  return (
    <header className={styles.wrapper}>
      <div className={styles.wrapper__left}>
        <ModeSwitch />

        <LangDropdown />
      </div>

      <NavLink to={Path.SignIn} className={styles.wrapper__center}>
        <AmatuniPrimaryIcon />
        <h1 className={styles.wrapper__center__text}>AMATUNI</h1>
      </NavLink>

      <div className={styles.wrapper__right}>
        {/* <Button onClick={handleLogout} size='large' variant='primary'>
          logout
        </Button> */}
      </div>
    </header>
  )
}

export default Header
