import { lazy } from 'react'

import { Path } from './types'

const SignIn = lazy(() => import('pages/Auth/SignIn'))
const ResetPassword = lazy(() => import('pages/Auth/ResetPassword'))
const ForgotPassword = lazy(() => import('pages/Auth/ForgotPassword'))

const Home = lazy(() => import('pages/Dashboard'))

const Error = lazy(() => import('pages/Error'))

const routesList = [
  {
    element: SignIn,
    path: Path.SignIn,
    title: 'Amatuni Login',
    isPrivate: false,
  },
  {
    element: ForgotPassword,
    path: Path.ForgotPassword,
    title: 'Forgot Password',
    isPrivate: false,
  },
  {
    element: ResetPassword,
    path: Path.ResetPassword,
    title: 'Reset Password',
    isPrivate: false,
  },
  {
    element: Home,
    path: Path.Dashboard,
    isPrivate: true,
    title: 'Dashboard',
  },
  {
    element: Error,
    path: Path.Error,
    isPrivate: true,
    title: 'Error',
  },
]

export default routesList
