import { Path } from 'libraries/router/types'
import { BlogIcon, SettingsIcon, SupportIcon, HistoryIcon, StatsIcon } from 'assets'

import type { TSectionItem } from './types'

export const sidebarMenuItemsUtils: TSectionItem[] = [
  {
    title: 'General',
    menuItems: [
      {
        Icon: HistoryIcon,
        text: 'Activities',
        navigateTo: Path.Dashboard,
      },

      {
        Icon: StatsIcon,
        text: 'Stats',
        navigateTo: 'Stats',
      },
    ],
  },
  {
    title: 'Academy',
    menuItems: [],
  },
  {
    title: 'Other',
    menuItems: [
      {
        Icon: BlogIcon,
        text: 'Library',
        navigateTo: null,
      },
      {
        Icon: SettingsIcon,
        text: 'Settings',
        navigateTo: null,
      },
    ],
  },
]

export const mobileSidebarMenuItemsUtils: TSectionItem[] = [
  {
    title: 'Academy',
    menuItems: [],
  },
  {
    title: 'Other',
    menuItems: [
      {
        Icon: SettingsIcon,
        text: 'Settings',
        navigateTo: null,
      },
      {
        Icon: SupportIcon,
        text: 'Support',
        navigateTo: null,
      },
    ],
  },
]
